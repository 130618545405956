import React from "react";
import SearchResultPage from "@templates/search-results-template";
import Layout from "@components/layout";
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "@components/common/site/utils";

const SearchResultRent = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("lettings", fullpath);

    return (
            <SearchResultPage locationname="/property/to-rent/" pcategorytype="residential" ptype="lettings" ptypetag="to-rent" pstatustype="To Let" fullpathone={fullpathone} fullpathname={fullpath}/>
    );
};

export default SearchResultRent;
